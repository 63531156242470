<template lang="pug">
.q-mb-sm.q-ml-md(v-if="hrefTo && label")
    router-link.font-24.text-primary.text-weight-bold.decoration-no(
        :to="hrefTo"
        :title="label" itemprop="text") {{ label }}
.font-16.text-primary.text-weight-medium.q-mb-sm.q-ml-md(v-if="!hrefTo && label") {{ label }}
q-scroll-area.full-width.banner.q-px-sm(data-allow-mismatch
:thumb-style="{display: 'none'}")
    .row.no-wrap.q-pl-md
        .cursor-pointer.banner-slide(
            v-for="(slide, index) in slides"
            :key="index"
            :name="index"
            style="min-width: 80%"
        )
            //q-skeleton(
            //    v-if="imgLoad < 1"
            //    style="object-fit: contain; object-position: 50% 50%; border-radius: 16px; height: calc((80vw - 15px)* 0.61)"
            //)
            span.relative-position.banner-img
                img(
                    data-allow-mismatch
                    width="100%"
                    height="100%"
                    style="object-fit: contain; object-position: 50% 50%; border-radius: 16px"
                    @click="goTo(slide)"
                    :src="slide.image"
                    :title="`${slide.title}`"
                    :alt="`${slide.title}`"
                    @load="loadingImg"
                )
                .badge.text-center.flex.column.justify-center(v-if="slide.count")
                    .font-12.text-secondary.text-weight-bold.line-height-12
                        | {{ slide.count }}
                    .font-10.text-secondary.text-weight-medium.line-height-10
                        | товаров
                .adv-badge-wrap(v-if="slide.token")
                    .adv-badge.flex.items-center(@click.prevent.stop="showModal(slide)")
                        q-icon(
                            name="etabl:info_warning"
                            size="14px"
                            color="primary"
                        )
                        span.font-12.text-primary(style="margin-left: 2px")
                            | Реклама
q-dialog(
    :model-value="showAdvModal"
    full-width
    position="bottom"
    transition-show="slide-up"
    transition-hide="slide-down"
    @hide="showAdvModal = false")
    .full-width.full-height.bg-secondary.b-radius-0.relative-position.q-pt-lg.q-px-md.bottom-popup-mobile
        .text-primary.text-center
            |  {{ activeAdv.organizationName }}, ИНН {{ activeAdv.inn }}
        base-btn.q-mt-md(
            label="Скопировать ссылку"
            @click="copy(`https://etabl.ru/marketing/${activeAdv.id}?token=${activeAdv.token}`)"
        )
        base-btn(
            label="Понятно"
            @click="showAdvModal = false"
        ).q-my-md

</template>

<script>
import { ref, watch, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { copyToClipboard } from 'quasar';

import BaseBtn from '../Base/BaseBtn.vue';

export default {
    name: 'CarouselMobile',
    props: {
        label: {
            type: String,
            default: null,
        },
        hrefTo: {
            type: String,
            default: '',
        },
        slides: {
            type: Array,
            required: true,
        },
    },
    components: {
        BaseBtn,
    },
    emits: ['imgLoaded'],
    setup(props, { emit }) {
        const store = useStore();
        const count = ref(0);
        const router = useRouter();
        const freeDelivery = ref(true);
        const showAdvModal = ref(null);
        const activeAdv = ref(null);

        const categories = ref(null);

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const goTo = (slide) => {
            if (slide.token?.length) {
                try {
                    ym(72234883, 'reachGoal', 'banner_click');
                } catch {
                }
            }


            try {
                window.appMetrica.reportEvent('Переход по рекламному баннеру', { 'Наименование акции': `${name}` });
            } catch {
            }

            if (slide.title == 'Бесплатная доставка') {
                router.push(city.value ? `/${city.value.slug}/catalog-list-mobile?freeDelivery=true` : '/catalog-list-mobile?freeDelivery=true');
                return;
            }

            if (slide.url) {
                if (slide.url.includes('design')) {
                    if (process.env.CLIENT) {
                        window.open(slide.url, '_blank');
                    }
                } else if (city.value) {
                    router.push(`/${city.value.slug}${slide.url}`);
                    return;
                } else {
                    router.push(`${slide.url}`);
                    return;
                }
            }

            if (slide.token) {
                if (city.value) {
                    router.push(`/${city.value.slug}/marketing/${slide.id}?token=${slide.token}`);
                    return;
                } else {
                    router.push(`/marketing/${slide.id}?token=${slide.token}`);
                    return;
                }
            } else {
                if (city.value) {
                    router.push(`/${city.value.slug}/marketing/${slide.id}`);
                    return;
                } else {
                    router.push(`/marketing/${slide.id}`);
                    return;
                }
            }
        };

        const showModal = (value) => {
            activeAdv.value = value;
            showAdvModal.value = true;
        };

        const copy = (value) => {
            copyToClipboard(value);
        };
        const imgLoad = ref(0);

        const loadingImg = () => {
            imgLoad.value += 1;
            emit('imgLoaded');
        };

        return {
            count,
            categories,
            goTo,
            showAdvModal,
            activeAdv,
            showModal,
            copy,
            city,
            imgLoad,
            loadingImg,
        };
    },
};
</script>

<style lang="scss" scoped>
.banner-img {
    display: block;
    border-radius: 10px;
    overflow: hidden;
    height: calc((80vw - 15px) * 0.61);
    background-size: cover !important;
    background-position: center !important;
}

.banner-slide {
    border-radius: 16px;
    margin-right: 16px;

    &:last-child {
        margin-left: 16px;
    }
}

.banner {
    height: calc((80vw - 15px) * 0.61);
}

.badge {
    position: absolute;
    bottom: 60%;
    width: 54px;
    height: 32px;
    background: $negative;
    border-radius: 14px 0 0 14px;
    right: 0;
    padding: 0;
}

.adv-badge-wrap {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 0;
    background: none;
}

.adv-badge {
    border-radius: 10px;
    background: $secondary;
    padding: 2px 4px;
}

.bottom-popup-mobile {
    border-radius: $mobile-border-radius $mobile-border-radius 0 0 !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    padding-bottom: calc(8px + constant(safe-area-inset-bottom));
    padding-bottom: calc(8px + env(safe-area-inset-bottom));
}
</style>
